<template>
    <div class="card" style="margin-top:0;">
      <div class="card-header card-header-icon card-header-rose">
        <div class="card-icon">
          <i class="material-icons">notes</i>
        </div>
        <h3 class="card-title">BIOMASA (TON) POR MES Y AÑO</h3>
      </div>
      <div class="card-body">
        <v-row class="mx-0">
          <v-col cols="3">
            <v-select prepend-icon="mdi-navigation" v-model="periodo" :items="periodos" item-text="anio" item-value="id"
              label="Seleccione Año" color="blue darken-3" multiple :reduce="item => item.id"></v-select>
          </v-col>
          <v-col cols="3">
            <v-select prepend-icon="mdi-navigation" v-model="faena" :items="faenas" item-text="nombre" item-value="id"
              label="Tipo de Faena" color="blue darken-3"></v-select>
          </v-col>
          <v-col class="text-right" cols="6">
            <v-btn class="mx-2" dark color="blue darken-1" @click="generar_reporte"><i class="material-icons">cached</i>
              Generar</v-btn>
            <v-btn class="mx-2" dark color="green" v-show="url" @click="descargar_excel"><i class="material-icons">text_snippet</i> Excel</v-btn>
            <!-- <v-btn class="mx-2" dark @click="downloadPDF" color="#C62828"> <i class="material-icons">picture_as_pdf</i> PDF</v-btn> -->
          </v-col>
        </v-row>
  
        <spinner v-if="spinner"></spinner>
  
        <v-simple-table v-if="data.length > 0">
          <thead>
            <tr>
              <th class="text-center">Año</th>
              <th class="text-center" v-for="p in periodos_data" :key="p">{{ p }}</th>
              <th class="text-center">Total General</th>
            </tr>
          </thead>
          <tbody class="bg-reporte-factura">
            <tr v-for="(fila, idx) in data" :key="fila.id" v-html="retornar_columnas(fila, idx)">
  
            </tr>
          </tbody>
        </v-simple-table>
  
  
      </div>
  
  
    </div>
  </template>
  <style scoped>
  .table-wellboat {
    font-size: 16pt;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  
  .card {
    margin-top: 0;
  }
  
  .card .card-header-rose .card-icon {
    background: #263238;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(32, 30, 31, 0.822);
  }
  </style>
  <script>
  import { mapState } from "vuex";
  
  export default {
    data: () => ({
      spinner: false,
      meses: [],
      periodos: [],
      faenas: [
        { id: 0, nombre: 'Todos' },
        { id: 1, nombre: 'Cosecha' },
        { id: 3, nombre: 'Smolt' },
        { id: 4, nombre: 'Mortalidad' },
        { id: 5, nombre: 'Baño' },
        { id: 6, nombre: 'Vacuna' },
      ],
      periodo: [],
      periodos_data:[],
      faena: 0,
      data: [],
      url:null
  
    }),
    mounted() {
      this.cargar_periodos()
    },
    methods: {
        async cargar_periodos() {
        let url = `${this.base_url}reportes/sw/cargar_periodos`;
        await this.axios
          .get(url, this.headers)
          .then((response) => {
            this.periodos = response.data.periodos;
            //this.periodo = this.periodos[0]
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async generar_reporte() {
        let url = `${this.base_url}reportes/sw/reporte_biomasa_mensual_anual`;
        let body = {
          periodo: this.periodo,
          faena: this.faena
        }
        await this.axios
          .post(url, body, this.headers)
          .then((response) => {
            console.log('response', response.data)
            this.data = response.data.data
            this.periodos_data = response.data.periodos
            this.url = response.data.url
          })
          .catch((error) => {
            console.log(error);
          });
      },
      retornar_columnas(fila, idx) {
  
        let str = `<td>${fila.mes}</td>`
        let ultimo_index = fila.periodos.length - 1
        let ultimo_idx = this.data.length - 1
        fila.periodos.forEach((p, index) => {
          let clase = '';
          if (index == ultimo_index && idx != ultimo_idx) {
            clase = 'bg-yellow'
          }
          str += `<td class="${clase}">${this.$options.filters.formatear_miles(p.biomasa)}</td>`
        });
        return str
  
      },
      descargar_excel(){
        window.open(this.url);
      }
    },
    computed: {
      ...mapState(["storage_url", "loading", "base_url", "headers", "user"]),
      cabeceras() {
        let string = ``;
        this.meses.forEach(mes => {
          mes.mes
          string += `<th class="text-center">N° Viajes</th>
                      <th class="text-center">Biomasa (Ton)</th>
                      <th class="text-center">Servicio (USD)</th>`
        });
        return string;
      }
    },
    components: {},
  };
  </script>
  <style>
  th {
    background-color: #355f94;
    color: #fff !important;
    border: 1px solid #000301;
  }
  tbody.bg-reporte-factura tr:last-child{
          background: #ced4da;
          font-weight: 500;
      }
  
      
  .bg-yellow{
    background-color: #fff2b2;
    font-weight: 500;
  }
  </style>
    